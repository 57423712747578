import React from "react";
import { Link } from "react-router-dom";

const _Footer = () => {
    return (
        <footer>
            <div className="main-footer py-5 border-bottom-white">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ul className="footer-menu d-lg-flex flex-lg-row flex-lg-wrap justify-content-evenly list-unstyled mb-0 text-color-white">
                                <li><Link href="#">ΧΡΗΣΙΜΕΣ ΠΛΗΡΟΦΟΡΙΕΣ</Link></li>
                                <li><Link href="#">ΟΡΟΙ ΧΡΗΣΗΣ</Link></li>
                                <li><Link href="#">EΠΙΚΟΙΝΩΝΙΑ</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-footer py-3">
                <div className="row mx-0">
                    <div className="col-12">
                        <p className="small-text text-center text-color-white mb-0">Copyright ©️ 2023 OTEUltrafast All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default _Footer;