import {React, useEffect} from 'react';
import { Link } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';



import homeimag1 from "../assets/images/home-imag-1.webp";
import maskgroup25 from "../assets/images/mask-group-25.webp";
import maskgroup46 from "../assets/images/mask-group-46.webp";
import maskgroup47 from "../assets/images/mask-group-47.webp";
import maskgroup58 from "../assets/images/mask-group-58.webp";


const PageHome = () => {

    useEffect(() => {
        document.body.classList.add('home');
        return () => {
          document.body.classList.remove('home');
        }
    }, [])


    return (
        <>
            <section>
                <div className="home-top-section vh-100 w-100 py-4 py-lg-5">
                    <div className="container h-100">
                        <div className="col-12 col-xl-6 inner-wrap d-flex flex-column justify-content-center justify-content-xl-end h-100 pb-lg-5 mb-lg-5 mt-5 mt-xl-0 ">
                            <h2 className="text-color-white">Διαμορφώνουμε τις <span className="highlight-text">υποδομές</span> που
                                οδηγούν την Ελλάδα στο
                                μέλλον</h2>
                            <p className="text-color-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut
                                labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                            <div className="change-section-icon py-3 text-center text-xl-start">
                                <Link href="#sxetika-me-emas">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.108" height="74.326" viewBox="0 0 18.108 74.326">
                                        <path data-name="Line 30" transform="translate(8.707 .5)" 
                                        style={{fill:"none", stroke:"#fff", strokeLinecap:"round"}}
                                        d="M0 0v73" />
                                        <path data-name="Path 160" d="m2171-608 8.347 8.347 8.347-8.347" transform="translate(-2170.293 673.272)" 
                                        style={{fill:"none", stroke:"#fff", strokeLinecap:"round"}}
                                        />
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="sxetika-me-emas">
                <div className="inner-wrap py-4 py-lg-5">
                    <div className="container my-4 my-lg-5">
                        <div className="row my-lg-4">
                            <div className="col-xl-6">
                                <h3 className="text-color-blue text-uppercase">ΣΧΕΤΙΚΑ ΜΕ ΕΜΑΣ</h3>
                                <h4 className="mb-4">Ένα καλύτερο δίκτυο για έναν καλύτερο κόσμο</h4>
                            </div>
                        </div>
                        <div className="row my-lg-4 align-items-center">
                            <div className="col-lg-6 mb-4 mb-lg-0">
                                <img src={homeimag1} alt="" />
                            </div>
                            <div className="col-lg-6">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                    ut
                                    labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                <Link href="#" className="btn-style-1"><span>ΠΕΡΙΣΣΟΤΕΡΑ</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="inner-wrap home-network-section w-100 py-4 py-lg-5">
                    <div className="container my-4 ">
                        <div className="row">
                            <div className="col-12 d-flex flex-column align-items-center">
                                <h3 className="text-color-white text-center text-uppercase">ΔΙΚΤΥΟ</h3>
                                <h4 className="text-color-white text-center">Νομοί & Ευρυζωνικότητα</h4>
                                <p className="text-color-white text-center mb-0">Eνημερώσου για την ανάπτυξη των δικτύων οπτικών ινών
                                </p>
                                <img src={maskgroup58} className="home-img-map-greece my-3 my-lg-4" alt="" />
                                <Link href="#" className="btn-style-2 "><span>ΠΕΡΙΣΣΟΤΕΡΑ</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="inner-wrap home-news-section  py-4 py-xl-5 my-lg-5">
                    <div className="container my-4 my-lg-5">
                        <div className="row">
                            <div className="col-12 d-flex flex-column align-items-center mb-3 mb-lg-5">
                                <h3 className="text-color-blue text-center">ΤΕΛΕΥΤΑΙΑ ΝΕΑ</h3>
                                <h4 className="text-center">Νομοί & Ευρυζωνικότητα</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 px-0" >
                                <Swiper className="swiper latest-news-swiper-carousel-home px-lg-2"
                                    modules={[Pagination]}
                                    spaceBetween={20} 
                                    slidesPerView={1.2}
                                    centeredSlides={true}
                                    freeMode={false}
                                    pagination={{ clickable: true }}
                                    breakpoints = {{
                                        768: {
                                            slidesPerView: 2.2,
                                            centeredSlides: false,
                                            freeMode: false,
                                            slidesPerGroup: 1,
                                            spaceBetween: 20,
                                            grabCursor: true,
                                        },
                                        1200: {
                                            slidesPerView: 3,
                                            centeredSlides: false,
                                            freeMode: true,
                                            slidesPerGroup: 1,
                                            spaceBetween: 20,
                                            grabCursor: true,
                                        }
                                    }}
                                    // onSlideChange={() => console.log('slide change')} 
                                    // onSwiper={(swiper) => console.log(swiper)} 
                                >
                                    <SwiperSlide className="latest-news-item swiper-slide">
                                        <div className="news-image">
                                            <Link href="#"><img src={maskgroup25} alt="" /></Link>
                                        </div>
                                        <div className="news-content">
                                            <div className="news-date small-text my-2">
                                                <span>29 Ιουνίου, 2023</span>
                                            </div>
                                            <div className="news-title">
                                                <h5 className="mb-2">
                                                    <Link href="#">Σημαντικές επενδύσεις σε οπτική ίνα και δίκτυα νέας γενιάς</Link>
                                                </h5>
                                            </div>
                                            <div className="news-desc">
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit…</span>
                                            </div>
                                        </div>
                                        <div className="news-read-more">
                                            <Link className="btn-style-1 small-text text-uppercase" href="#">ΠΕΡΙΣΣΟΤΕΡΑ</Link>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="latest-news-item swiper-slide">
                                        <div className="news-image">
                                            <Link href="#"><img src={maskgroup46} alt="" /></Link>
                                        </div>
                                        <div className="news-content">
                                            <div className="news-date small-text my-2">
                                                <span>05 Μαΐου, 2023</span>
                                            </div>
                                            <div className="news-title">
                                                <h5 className="mb-2">
                                                    <Link href="#">Το γρήγορο internet και οι οπτικές ίνες έρχονται σε 3.000.000 σπίτια</Link>
                                                </h5>
                                            </div>
                                            <div className="news-desc">
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit…</span>
                                            </div>
                                        </div>
                                        <div className="news-read-more small-text">
                                            <Link className="btn-style-1 small-text text-uppercase" href="#">ΠΕΡΙΣΣΟΤΕΡΑ</Link>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="latest-news-item swiper-slide">
                                        <div className="news-image">
                                            <Link href="#"><img src={maskgroup47} alt="" /></Link>
                                        </div>
                                        <div className="news-content">
                                            <div className="news-date small-text my-2">
                                                <span>13 Φεβρουαρίου, 2023</span>
                                            </div>
                                            <div className="news-title">
                                                <h5 className="mb-2">
                                                    <Link href="#">16 νέοι οικισμοί για διάθεση υπηρεσιών στην OTE UltraFast από 22/2/2023</Link>
                                                </h5>
                                            </div>
                                            <div className="news-desc">
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit…</span>
                                            </div>
                                        </div>
                                        <div className="news-read-more small-text">
                                            <Link className="btn-style-1 small-text text-uppercase" href="#">ΠΕΡΙΣΣΟΤΕΡΑ</Link>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="latest-news-item swiper-slide">
                                        <div className="news-image">
                                            <Link href="#"><img src={maskgroup25} alt="" /></Link>
                                        </div>
                                        <div className="news-content">
                                            <div className="news-date small-text my-2">
                                                <span>29 Ιουνίου, 2023</span>
                                            </div>
                                            <div className="news-title">
                                                <h5 className="mb-2">
                                                    <Link href="#">Σημαντικές επενδύσεις σε οπτική ίνα και δίκτυα νέας γενιάς</Link>
                                                </h5>
                                            </div>
                                            <div className="news-desc">
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit…</span>
                                            </div>
                                        </div>
                                        <div className="news-read-more small-text">
                                            <Link className="btn-style-1 small-text text-uppercase" href="#">ΠΕΡΙΣΣΟΤΕΡΑ</Link>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="latest-news-item swiper-slide">
                                        <div className="news-image">
                                            <Link href="#"><img src={maskgroup46} alt="" /></Link>
                                        </div>
                                        <div className="news-content">
                                            <div className="news-date small-text my-2">
                                                <span>05 Μαΐου, 2023</span>
                                            </div>
                                            <div className="news-title">
                                                <h5 className="mb-2">
                                                    <Link href="#">Το γρήγορο internet και οι οπτικές ίνες έρχονται σε 3.000.000 σπίτια</Link>
                                                </h5>
                                            </div>
                                            <div className="news-desc">
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit…</span>
                                            </div>
                                        </div>
                                        <div className="news-read-more small-text">
                                            <Link className="btn-style-1 small-text text-uppercase" href="#">ΠΕΡΙΣΣΟΤΕΡΑ</Link>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="latest-news-item swiper-slide">
                                        <div className="news-image">
                                            <Link href="#"><img src={maskgroup47} alt="" /></Link>
                                        </div>
                                        <div className="news-content">
                                            <div className="news-date small-text my-2">
                                                <span>13 Φεβρουαρίου, 2023</span>
                                            </div>
                                            <div className="news-title">
                                                <h5 className="mb-2">
                                                    <Link href="#">16 νέοι οικισμοί για διάθεση υπηρεσιών στην OTE UltraFast από 22/2/2023</Link>
                                                </h5>
                                            </div>
                                            <div className="news-desc">
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit…</span>
                                            </div>
                                        </div>
                                        <div className="news-read-more small-text">
                                            <Link className="btn-style-1 small-text text-uppercase" href="#">ΠΕΡΙΣΣΟΤΕΡΑ</Link>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default PageHome;