import { React, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  _AnimationLayout,
  _Header,
  _Footer,
  PageHome,
  PageAbout,
  PageServices,
  PageNetwork

} from "./components";

import './assets/css/style.css';
import './assets/css/extra.css';

const App = () => {

  useEffect(() => {
    const handleScroll = (event) => {
      // console.log('Page scrolled:', window.scrollY);
      if (window.scrollY > 200) {
        document.getElementById('header').classList.add('sticky');
      } else {
        document.getElementById('header').classList.remove("sticky");
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <BrowserRouter>
        <_Header />
          <Routes >
            <Route element={<_AnimationLayout />}  >
              <Route exact path="/" element={ <PageHome /> } />
              <Route path="/about" element={ <PageAbout /> } />
              <Route path="/services" element={ <PageServices /> } />
              <Route path="/network" element={ <PageNetwork /> } />
            </Route>
          </Routes>
        <_Footer />
    </BrowserRouter>
  );
}

export default App;
