import React from "react";
import { Link } from "react-router-dom";

import logo_color from "../assets/images/Ultrafast_logo_colorful.webp";
import logo_white from "../assets/images/ultrafast-logo-white.webp";

const _Header = () => {
    return (
        <header id="header" className="position-absolute w-100 border-bottom-white">
            {/* <header class="w-100 border-bottom-black white-header"></header> */}
            <div className="container">
                <div className="header-inner py-4 d-flex align-items-center justify-content-between">
                    <div className="logo">
                        <Link to="/" title={"OTE Ultrafast"} className="home-logo" ><img src={logo_white} alt={"ote-ultrafast-logo"} /></Link>
                        <Link to="/" title={"OTE Ultrafast"} className="inner-logo" ><img src={logo_color} alt={"ote-ultrafast-logo"} /></Link>
                    </div>
                    <nav className="header-menu d-none d-lg-flex">
                        <div className="main-menu">
                            <ul className="d-lg-flex flex-lg-row flex-lg-wrap list-unstyled mb-0 text-color-white">
                                <li className="mx-4 menu-item li-about"><Link to={"/about"} className=""><span>Η ΕΤΑΙΡΙΑ</span></Link></li>
                                <li className="mx-4 menu-item li-services"><Link to={"/services"} className=""><span>YΠΗΡΕΣΙΕΣ</span></Link></li>
                                <li className="mx-4 menu-item li-network"><Link to={"/network"} className=""><span>ΔΙΚΤΥΟ</span></Link></li>
                                <li className="mx-4 menu-item li-news"><Link to={"#"} className=""><span>ΝΕΑ</span></Link></li>
                            </ul>
                        </div>
                        <div className="lang-menu">
                            <ul className="d-lg-flex flex-lg-row flex-lg-wrap list-unstyled ms-5 mb-0">
                                <li><Link to={"#"} className="px-1"><span>EN/GR</span></Link></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    )
}
export default _Header;