import {React, useEffect, useMemo, useCallback, useState } from 'react';
import { 
    GoogleMap, 
    Marker, 
    useLoadScript,
    DrawingManager,
    Data  
} from "@react-google-maps/api";

 

import mapStyles from '../assets/map/map-styles.json';

import rsca from '../assets/map/geoJson/RSCA.json';
import occa from '../assets/map/geoJson/OCCA.json';


const lib = ["places", "geometry", "visualization", "drawing"];
let marker_center = null;

const PageNetwork = () => {

    const [map, setMap] = useState(null);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDlOIG0H9wZ4uj9eIG8KqcNWJn0MP7_Z1k',
        libraries: lib
    });

    const onLoadMap = useCallback(function callback(map) {
        setMap(map);

        var data_rsca = new window.google.maps.Data({map: map});
        var data_occa = new window.google.maps.Data({map: map});
        var infowindow = new window.google.maps.InfoWindow();

        /* rsca -> use addGeoJson to load json data feching them str8 from the file system */
        data_rsca.addGeoJson(rsca);
        data_rsca.setStyle({
            fillColor: 'green',
            strokeWeight: 1
        });
        data_rsca.addListener('click', function(event) {
            console.log(event);
            // return; --->  if you do not want to show
            let state = event.feature.getProperty("elstat_id");
            let html = 'Περιοχή (rsca): ' + state; // combine state name with a label
            infowindow.setContent(html); // show the html variable in the infowindow
            infowindow.setPosition(event.latLng); // anchor the infowindow at the marker
            infowindow.setOptions({pixelOffset: new window.google.maps.Size(0,-30)}); // move the infowindow up slightly to the top of the marker icon
            infowindow.open(map);
        });

        // data_rsca.forEach( function(d1, ind) {
        //      console.log(d1);
        // });
        
        
        /* occa  -> use loadGeoJson to load json data feching them from other source with ajax (CORS policy to fix) */
        // data_occa.loadGeoJson('http://localhost:3000/map-data/geoJson/OCCA.json', null, callBack_data_occa);
        data_occa.addGeoJson(occa);
        data_occa.setStyle({
            fillColor: 'red',
            strokeWeight: 1
        });
        data_occa.addListener('click', function(event) {
          let state = event.feature.getProperty("oc_id");
          console.log(event);
          let html = 'Περιοχή (occa): ' + state; // combine state name with a label
          infowindow.setContent(html); // show the html variable in the infowindow
          infowindow.setPosition(event.latLng); // anchor the infowindow at the marker
          infowindow.setOptions({pixelOffset: new window.google.maps.Size(0,-30)}); // move the infowindow up slightly to the top of the marker icon
          infowindow.open(map);
        });

        // autocomplete
        let input = document.getElementById('map-search');
        let autocomplete = new window.google.maps.places.Autocomplete(input);
        autocomplete.addListener("place_changed", () => { 
            handleAddress( document.getElementById('map-search').value, map );
        });



    }, []);

    const onUnmount = useCallback(function callback(map) {
        setMap(null);
    }, []);

    const center = useMemo(() => ({ lat: 38.4042, lng: 23.0243 }), []);

    useEffect(() => {
        document.body.classList.add('inner');
        document.body.classList.add('network');
        document.getElementsByClassName('li-network')[0].classList.add('current-menu-item');
        return () => {
          document.body.classList.remove('inner');
          document.body.classList.remove('network');
          document.getElementsByClassName('li-network')[0].classList.remove('current-menu-item');
        }
    }, [])

 
    const onLoadData = (data) => {
        // console.log('data: ', data);
    };

    const callBack_data_occa = (dt_occa) => {
        // console.log(dt_occa);
    }

    const handleSearchFormSubmit = (e) => {
        e.preventDefault();
        let adr = e.target[0].value;
        handleAddress(adr, map);
    }

    const handleAddress = (addressString, map) => {
        console.log(addressString);
        let geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({'address': addressString}, function(results, status) {
            if (status === window.google.maps.GeocoderStatus.OK) {
                console.log(results);
                map.setOptions({
                    zoom: 17,
                    center: results[0].geometry.location.toJSON()
                });
                if (marker_center) 
                    marker_center.setMap(null);
                    marker_center = new window.google.maps.Marker({
                    position: results[0].geometry.location.toJSON(),
                    map: map,
                });

            } else {
                alert('Geocode was not successful for the following reason: ' + status);
                return false;
                // return null;
            }
        });
    }

    return (
        <>
            <section className="main-content" >
                <div className="container">
                    <div className="row justify-content-center py-5">
                        <div className="col-10 py-5">
                            <h1 className="text-center mt-1 mb-4" >Νομοί & Ευρυζωνικότητα</h1>
                            <p className="text-center" >Εισάγετε διεύθυνση, περιοχή ή Τ.Κ. για να δείτε αν έχει ολοκληρωθεί η κατασκευή της υποδομής στην περιοχή σας</p>
                            <div className="row justify-content-center"  >
                                <form id="search-form" onSubmit={handleSearchFormSubmit}>
                                    <input className="map-serach-field" type="text" id="map-search" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="main-map" >
                {!isLoaded ? (
                    <h1 style={{width:"100%", textAlign:"center", padding:"200px 0"}}>Loading...</h1>
                ) : (
                    <GoogleMap
                        mapContainerClassName="map-container"
                        center={center}
                        zoom={9}
                        options={{ styles: mapStyles.stylesArray }}
                        onLoad={onLoadMap}
                        onUnmount={onUnmount}
                     >
                        <Data
                            onLoad={onLoadData}
                        ></Data>
                    </GoogleMap>
                )}
            </section>
            <section className="search-results" >
                <div className="container pb-5">
                    <div className="row justify-content-center py-5 ">
                        <div className="col-12 pb-5">
                            <div className="row pb-5" >
                                <div className="search-area" >
                                    <span>1GB</span>
                                    <span>Ν. Ηράκλειο <br/> ΑΤΤΙΚΗ</span>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-5">
                                    <div className="results-left-box">
                                        <p>Η κατασκευή του δικτύου οπτικής Ίνας στην περιοχή έχει ολοκληρωθεί</p>
                                    </div>
                                </div>
                                <div className="col-1"></div>
                                <div className="col-6">
                                    <div className="results-right-box">
                                        <h2>Οφέλη του UFBB</h2>
                                        <ul>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PageNetwork;