import {React, useEffect} from 'react';
import { Link } from "react-router-dom";
import heroServices from "../assets/images/service/Evrizonikotita_banner_imag.webp";

const PageServices = () => {

    useEffect(() => {
        document.body.classList.add('inner');
        document.body.classList.add('services');
        document.getElementsByClassName('li-services')[0].classList.add('current-menu-item');
        return () => {
          document.body.classList.remove('inner');
          document.body.classList.remove('services');
          document.getElementsByClassName('li-services')[0].classList.remove('current-menu-item');
        }
    }, [])


    return (
        <>
            <section>
                <div className="container-fluid p-0 m-0">
                    <div className="col-12">
                        <img src={heroServices} alt={""} className="header-hero-image" />
                    </div>
                </div>
            </section>
            <section className="breadcrumbs" >
                <div className="container">
                    <div className="col-12 py-3">
                        <span>HOME</span> / <span>ΥΠΗΡΕΣΙΕΣ</span> / <Link to="#" >ΕΥΡΥΖΩΝΙΚΟΤΗΤΑ</Link>
                    </div>
                </div>
            </section>
            <section className="main-content" >
                <div className="container pb-5">
                    <div className="row justify-content-center py-5">
                        <div className="col-10 py-5">
                            <h1 className="mt-5 mb-5" >Ευρυζωνικότητα</h1>
                            <div className="row gx-4" >
                                <div className="service-box-left text-center col ">
                                    <div className="service-box-wrapper">
                                        <Link to="#">VLU FTTT</Link>
                                    </div>
                                </div>
                                <div className="service-box-right text-center col  ">
                                    <div className="service-box-wrapper">
                                        <Link to="#">VLU FTTC</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PageServices;